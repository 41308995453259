import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

// Sections
import Hero from '../../components/servicesSections/Hero';
import Journey from '../../components/servicesSections/Journey';
import OurClients from '../../components/servicesSections/OurClients';

const pageTitle = 'Software Services in Denver & Portland | Devetry';
const pageDescription =
  'Explore our software services and meet our team! Offices in Denver and Portland, with worldwide reach.';

const ServicesPageTemplate = ({
  heroSteps,
  service_items,
  client_logos,
  tabletQuoteHeight
}) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container">
        <Hero steps={heroSteps} tabletQuoteHeight={tabletQuoteHeight} />
        <Journey items={service_items} />
        <OurClients logos={client_logos} />
      </div>
    </>
  );
};

ServicesPageTemplate.propTypes = {};

const ServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <ServicesPageTemplate
      heroSteps={frontmatter.steps}
      service_items={frontmatter.service_items}
      client_logos={frontmatter.client_logos}
      tabletQuoteHeight={frontmatter.tabletQuoteHeight}
    />
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default ServicesPage;

export const pageQuery = graphql`
  query ServicesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Services/index" } }) {
      frontmatter {
        templateKey
        steps {
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 900, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          logo {
            src {
              childImageSharp {
                fluid(maxHeight: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          quote
          title
          cta {
            text
            link
          }
        }
        service_items {
          title
          body
          color
          cardLinks
        }
        tabletQuoteHeight
        client_logos {
          src {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
  }
`;
