import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { useInterval } from '../../../utils/hooks';

import { TButton } from '../../Button';
import './index.css';
import workDots from '../../../img/work-dots.svg';
import zebra from '../../../img/services-hero-zebra.svg';

const defaultDelay = 12000;

export default ({ steps, tabletQuoteHeight }) => {
  const [delay, setDelay] = useState(defaultDelay);
  const [categories, setCategories] = useState([
    'Strategy',
    'Design',
    'Product',
    'Technology',
    'Innovation',
  ]);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 100);
  }, [categories[2]]);

  useEffect(() => {
    if (!delay) setDelay(defaultDelay);
  }, [delay]);

  useInterval(() => {
    setReset(true);
    nextCategory(1);
    setReset(false);
  }, delay);

  const nextCategory = (index) => {
    switch (categories[index]) {
      case 'Strategy':
        setCategories([
          'Technology',
          'Innovation',
          'Strategy',
          'Design',
          'Product',
        ]);
        break;
      case 'Product':
        setCategories([
          'Strategy',
          'Design',
          'Product',
          'Technology',
          'Innovation'
        ]);
        break;
      case 'Design':
        setCategories([
          'Innovation',
          'Strategy',
          'Design',
          'Product',
          'Technology',
        ]);
        break;
      case 'Innovation':
        setCategories([
          'Product',
          'Technology',
          'Innovation',
          'Strategy',
          'Design',
        ]);
        break;
      case 'Technology':
        setCategories([
          'Design',
          'Product',
          'Technology',
          'Innovation',
          'Strategy',
        ]);
        break;
      default:
        break;
    }
  };

  const handleOnClick = (index) => {
    setReset(true);
    setDelay(null);
    nextCategory(index);
    //Wait 100ms to give page time to change UI and prevent timing syncing issues with UI loading bar
    setTimeout(() => {
      setReset(false);
      setDelay(defaultDelay);
    }, 100)
  };

  const getStep = useCallback(
    () =>
      steps.find(
        (step) => step.title.toLowerCase() === categories[2].toLowerCase()
      ),
    [categories[2]]
  );

  return (
    <section className="services-hero">
      <div className="c-container">
        <div className="left">
          <img
            className="top-dots"
            src={workDots}
            alt="Dots"
          />
          <img
            className="bottom-dots"
            src={workDots}
            alt="Dots"
          />
          <div className="links">
            <div onClick={() => handleOnClick(0)} className="link">
              <div className="left-inner">
                <p>{categories[0]}</p>
              </div>
            </div>
            <div onClick={() => handleOnClick(1)} className="link">
              <div className="left-inner">
                <p>{categories[1]}</p>
              </div>
            </div>
            <div className={`link active ${reset ? 'reset' : ''} `}>
              <div className="left-inner">
                <img
                  src={require(`../../../img/${categories[2].toLowerCase()}-white.svg`).default}
                />
                <p>{categories[2]}</p>
              </div>
              <div className="progress-bar">
                <div></div>
              </div>
            </div>
            <div onClick={() => handleOnClick(3)} className="link">
              <div className="left-inner">
                <p>{categories[3]}</p>
              </div>
            </div>
            <div onClick={() => handleOnClick(4)} className="link">
              <div className="left-inner">
                <p>{categories[4]}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="center">
          {steps.map((step, index) => (
            <Img
              key={index}
              className={`center-image ${
                step.title.toLowerCase() === categories[2].toLowerCase()
                  ? 'active'
                  : ''
              }`}
              fluid={step.image.src.childImageSharp.fluid}
              alt={step.image.alt}
            />
          ))}
        </div>
      </div>
      <div className="right" 
      style={{ height: tabletQuoteHeight, backgroundImage: `url(${zebra})` }}
      >
        <Img
          className="quote-logo"
          fluid={getStep().logo.src.childImageSharp.fluid}
          alt={getStep().logo.alt}
          imgStyle={{
            objectFit: "contain",
          }}
        />
        <div className="quote">
          <p className="start-quote">“</p>
          <p className="text-quote">
            {
              steps.find(
                (step) =>
                  step.title.toLocaleUpperCase() ===
                  categories[2].toLocaleUpperCase()
              ).quote
            }
          </p>
          <p className="end-quote">“</p>
        </div>
        <Link to={getStep().cta.link} className="hero-cta-link">
          <TButton text={getStep().cta.text} />
        </Link>
      </div>
    </section>
  );
};
 