import React, { useState } from 'react';

import { Link } from 'gatsby';

import Dots from '../../Dots';
import { TButton } from '../../Button';
import Arrow from '../../ArrowButton';
import './index.css';

export default ({ items }) => {
  const [hover, setHover] = useState(null);

  return (
    <section className="services-journey">
      <h1>Wherever you are on your software journey, we can help!</h1>
      {items.map(({ title, color, body, cardLinks }, index) => (
        <div
          key={index}
          className={`journey-item ${hover === index ? 'active' : ''}`}
        >
          <Link
            to={`/services/${
              title === 'Product Management'
                ? 'product-management'
                : title === 'Design'
                ? 'ux-design'
                : title.toLowerCase()
            }`}
          >
            <div className="left">
              <div>
                <h4>{title}</h4>
                <Dots amount={3} />
              </div>
              <p>{body}</p>
            </div>
            <div className="right" style={{ backgroundColor: color }}>
              <Dots amount={3} />
              <p>{title}</p>
              <div className="list">
                <ul>
                  {cardLinks.map((link, index) => (
                    <li key={`link${index}`}>{link}</li>
                  ))}
                </ul>
                <Arrow />
                <TButton text="learn more" />
              </div>
            </div>
          </Link>
        </div>
      ))}
    </section>
  );
};
